const appName = "Wiki4fit Demo";
const domainName = "wiki4fit-demo";
const packageName = "br.com.wiki4fit.wiki4fitdemo";

const internalDomain = `https://internal.${domainName}.wiki4fit.com.br`;

// dominio utilizado no processo de build da PWA no buddy.works
const publicDomain = `${domainName}.wiki4fit.com.br`;

const appVersion = "10.0.2";
const branchId = 1;
const loginMainColor = "orange";
const loginAltColor = "black";
const oneSignalAppId = "cd34acb0-1b7b-4e9c-868b-9889726ec75d";
const extra = {
  oneSignalAppId,
}; // ADD {eas: { projectId: [...]}} copiado do CLI de build

module.exports = {
  domainName,
  packageName,
  appVersion,
  appName,
  branchId,
  loginMainColor,
  loginAltColor,
  extra,
  internalDomain,
  publicDomain,
};
